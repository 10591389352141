import { isDevEnv } from '~/helpers/common';

import { LocalStorageKey } from '~/constants/localStorageKey';

export function isUserAuthorized() {
  return !isDevEnv() || Boolean(localStorage.getItem(LocalStorageKey.Authorization));
}

export function authorizeUser() {
  localStorage.setItem(LocalStorageKey.Authorization, 'true');
}
