import React from 'react';

import { Box, Stack, Text } from '@chakra-ui/react';

import { ActionButton } from '~/components/common/ActionButton';

import { useQuoteRestart } from '~/hooks/useQuoteRestart';

import { Colors } from '~/constants/colors';

export interface FooterProps {
  isRestartButtonDisabled?: boolean;
  showRestartFormButton?: boolean;
  restartFormButtonLabel?: string;
  textContent?: string;
  restartButtonHandler?: () => void;
}

export interface FooterRemarkProps {
  text?: string;
}

const DEFAULT_FOOTER_REMARK =
  'Strelka LTD, trading as Fluffy, Firm Reference No. 986188, is an appointed representative of Innovative Risk Labs Ltd, which is authorised and regulated by the Financial Conduct Authority, Firm Reference No. 609155. Cover-More Blue Insurance Services Limited, trading as InsureYourPaws.co.uk is a private limited company incorporated in Ireland with company number 345681 and with its UK branch office registered at 82 Oxford Road, Uxbridge, Middlesex, UB8 1UX. It is authorised and regulated in the United Kingdom by the Financial Conduct Authority, Firm Reference No. 984290.';

export const Footer: React.FC<React.PropsWithChildren<FooterProps>> = function Footer({
  showRestartFormButton = false,
  isRestartButtonDisabled = false,
  restartFormButtonLabel,
  textContent,
  restartButtonHandler,
  children,
}) {
  const restartQuote = useQuoteRestart(restartButtonHandler);

  return (
    <Stack spacing={4}>
      {showRestartFormButton && (
        <ActionButton
          onPress={restartQuote}
          label={restartFormButtonLabel ?? 'Start again'}
          isSecondary
          disabled={isRestartButtonDisabled}
          style={{ margin: '0 auto', padding: '4px 8px' }}
        />
      )}

      {children}

      <FooterRemark text={textContent} />
    </Stack>
  );
};

const FooterRemark: React.FC<FooterRemarkProps> = function FooterRemark({ text }) {
  const textToDisplay = text ? text : DEFAULT_FOOTER_REMARK;

  return (
    <Box>
      <Text fontSize={{ base: '10px', lg: '12px' }} lineHeight="16px" fontWeight="400" color={Colors.PaleBrow}>
        {textToDisplay}
      </Text>
    </Box>
  );
};
